import React from 'react'
import { POKER, POKER_HANDS } from '../../components/internal-links'

import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'

import PokerLayout from '../../components/Poker/PokerLayout'
import { graphql, useStaticQuery } from 'gatsby'
import RenderHTML from '../../components/Poker/PokerVarientPages/RenderHTML'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
  POKER_VARIANTS_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'

const PokerHands: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(filter: { slug: { eq: "poker-hands" } }) {
        nodes {
          html
          title
          meta_title
          meta_description
        }
      }
    }
  `)

  const title = data.allGhostPost.nodes[0]?.title
  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: POKER_VARIANTS_BREADCRUMB_TITLE,
      url: POKER,
    },
    {
      title: data.allGhostPost.nodes[0]?.title,
      url: POKER_HANDS,
    },
  ]

  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  return (
    <>
      <PokerLayout
        pageName="custom"
        breadcrumbs={breadcrumbs}
        pageSlug={{
          POKER_LINK: POKER_HANDS,
        }}
        showFullScreenBanner={true}
        seoDetails={{
          title: metaTitle,
          description: metaDescription,
        }}
        showFeatures={true}
        showOtherContent={false}
        showVideos={false}
        englishContentHTML={
          <RenderHTML html={data.allGhostPost.nodes[0]?.html} title={title} />
        }
      />
    </>
  )
}

export default PokerHands
